<template>
    <!-- swcluster-header -->
    <CommonHeader />
    <router-view />
    <CommonFooter v-if="!hideFooter" />
    <CommonLoading v-if="loading" />
</template>

<script>
import CommonHeader from '@/components/common/layout/header/CommonHeader';
import CommonFooter from '@/components/common/layout/footer/CommonFooter';
import {computed} from 'vue';
import {useRoute} from 'vue-router';
import CommonLoading from '@/components/common/CommonLoading';
import {useStore} from 'vuex';

export default {
    name: 'CommonLayout',
    components: {CommonLoading, CommonHeader, CommonFooter},
    setup() {
        const store = useStore();
        const route = useRoute();
        const footerHideNames = [
            'Athena',
            'AthenaView',
            'GlueCrawler',
            'GlueJob',
            'DataLakeOrigin',
            'DataLakePublic',
            'DataMart',
            'Consulting',
            'ConsultingApply',
            'ConsultingView',
        ];

        const hideFooter = computed(() => footerHideNames.includes(route.name) || route.name.includes('Edit'));
        const loading = computed(() => store.state.common.loading.show);

        return {
            hideFooter,
            loading,
        };
    },
};
</script>
