<template>
    <div class="layer-container" id="layer-connecting">
        <div class="layer" style="background-color: transparent !important">
            <svg viewBox="0 0 620 300">
                <text x="50%" y="50%" dy=".35em" text-anchor="middle">Connecting</text>
            </svg>
            <!--            SageMaker 연결중입니다.-->
            <div v-if="title" class="connecting-content">{{ title }}</div>
        </div>
        <div class="layer-scrim"></div>
    </div>
</template>

<script>
import {useStore} from 'vuex';
import {computed} from 'vue';

export default {
    name: 'CommonLoading',
    setup() {
        const store = useStore();
        const title = computed(() => store.state.common.loading.title);
        return {
            title,
        };
    },
};
</script>
