<template>
    <header class="swcluster-header" :data-mode="headerMode">
        <div class="header-component">
            <!-- header-title -->
            <div class="header-column header-title" :class="{'swcc-admin': session.manager}">
                <h1 class="title">
                    <i class="title-logo swcc-cursor-pointer" @click="goMain"></i>
                    <!--                    <span class="title-text" :class="{'swcc-text-white': headerMode === 'dark'}">SW 융합클러스터</span>-->
                    <!--                    <span class="title-badge" :class="{'swcc-text-white': headerMode === 'dark'}">대구 ・ 제주센터</span>-->
                </h1>
            </div>
            <!-- header-gnb -->
            <CommonHeaderMenu :mode="headerMode" />

            <div class="header-column header-util">
                <div class="util-links util-divider">
                    <a v-if="session.manager" href="javascript:" class="link" :class="{'swcc-text-white': headerMode === 'dark'}" @click="manager"
                        >관리자 모드</a
                    >
                    <a v-if="session.name === null" href="javascript:" class="link" @click="login"
                        ><strong :class="{'swcc-text-white': headerMode === 'dark'}">로그인</strong></a
                    >
                    <a v-else href="javascript:" class="link" @click="logout"><strong :class="{'swcc-text-white': headerMode === 'dark'}">로그아웃</strong></a>
                </div>
            </div>

            <!--            <div class="header-column">-->
            <!--                <button v-if="session.name === null" class="swcc-login-btn" @click="login">-->
            <!--                    <span class="text">로그인</span>-->
            <!--                </button>-->
            <!--                <button v-else class="swcc-login-btn" @click="logout">-->
            <!--                    <span class="text">로그아웃</span>-->
            <!--                </button>-->
            <!--            </div>-->
        </div>
    </header>
</template>

<script>
import {computed, onMounted, onUnmounted, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import CommonHeaderMenu from '@/components/common/layout/header/CommonHeaderMenu';
import {checkAuthRoute, getAuthSession, getMenuName, removeAuthSession, setBeforeRoute} from '@/assets/js/modules/auth/module';
import ApiService from '@/assets/js/api.service';
import {isSuccess} from '@/assets/js/common.utils';

export default {
    name: 'CommonHeader',
    components: {CommonHeaderMenu},
    props: {},
    setup() {
        const route = useRoute();
        const router = useRouter();
        const scrollY = ref(0);
        const session = computed(getAuthSession);
        const necessaryAuthRoute = computed(() => checkAuthRoute(route.name));

        const darkHeaders = ['Main', 'Intro', 'Apply', 'AnalyticsGuid', 'VisualizationGuid', 'HubIntro'];
        const dynamicHeaders = ['Main', 'AnalyticsGuid', 'VisualizationGuid', 'HubIntro'];

        const standard = computed(() => {
            if (route.name === 'Main') {
                return 450;
            }
            return 200;
        });

        const headerMode = computed(() => {
            if (darkHeaders.includes(route.name)) {
                if (dynamicHeaders.includes(route.name) && scrollY.value > standard.value) {
                    return '';
                }

                return 'dark';
            }
            return '';
        });

        const goMain = e => {
            if (e.ctrlKey) {
                window.open('/');
            } else {
                router.push({name: 'Main'});
            }
        };

        const manager = e => {
            //const res = await ApiService.get('/v1/members', 'list');
            //console.log(res);
            //if (isSuccess(res)) {
            //await setBeforeRoute({name: getMenuName(route.path), uri: route.path});
            //await
            if (e.ctrlKey) {
                window.open('/');
            } else {
                router.push({name: 'Manager'});
            }
            //}
        };

        const setScroll = () => {
            scrollY.value = window.scrollY;
        };

        const login = async () => {
            await setBeforeRoute({name: getMenuName(route.path), uri: route.path});
            await router.push({name: 'SignIn'});
        };

        const logout = async () => {
            const res = await ApiService.delete('/v1/auths', 'sign-out');
            if (isSuccess(res)) {
                await removeAuthSession();
                if (necessaryAuthRoute.value) {
                    console.log(necessaryAuthRoute.value);
                    await router.push({name: 'Main'});
                }
            }
        };

        onMounted(() => {
            setScroll();
            document.addEventListener('scroll', setScroll);
        });

        onUnmounted(() => {
            document.removeEventListener('scroll', setScroll);
        });

        return {
            session,
            headerMode,
            login,
            logout,
            goMain,
            manager,
        };
    },
};
</script>
