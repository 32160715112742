<template>
    <footer class="swcluster-footer">
        <div class="footer-component">
            <div class="footer-column column-copyright">
                <p class="copyright">2022 Daegu Digital Innovation Promotion Agency & JEJU Techno Park. All rights reserved.</p>
            </div>
            <div v-if="isDark" class="footer-column column-logos">
                <a href="" class="logo"><img src="@/assets/images/common/logo_footer_dip_white.svg" alt="대구디지털혁신진흥원" /></a>
                <a href="" class="logo"><img src="@/assets/images/common/logo_footer_jtp_white.svg" alt="제주테크노파크" /></a>
            </div>
            <div v-else class="footer-column column-logos">
                <a href="javascript:" class="logo"><img src="@/assets/images/common/logo_footer_dip.svg" alt="대구디지털혁신진흥원" /></a>
                <a href="javascript:" class="logo"><img src="@/assets/images/common/logo_footer_jtp.svg" alt="제주테크노파크" /></a>
            </div>
        </div>
    </footer>
</template>

<script>
import {computed} from 'vue';
import {useRoute} from 'vue-router';

export default {
    name: 'CommonFooter',
    setup() {
        const route = useRoute();

        const darkRoutes = ['Apply', 'Intro'];
        const isDark = computed(() => darkRoutes.includes(route.name));
        return {
            isDark,
        };
    },
};
</script>
