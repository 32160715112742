<template>
    <div class="header-column header-gnb">
        <nav class="gnb">
            <ul class="gnb-list">
                <!--                    @mouseover="toggleActive(index, true)"-->
                <!--                    @mouseleave="toggleActive(index, false)"-->
                <template v-for="(parentsMenu, index) in menus" :key="index">
                    <li
                        v-if="!parentsMenu.condition || parentsMenu.condition(session)"
                        class="gnb-item"
                        :class="{'is-active': parentsMenu.toggle}"
                        style="height: 70px; display: flex; align-items: center"
                        @click.stop="toggleActive(index)">
                        <template v-if="parentsMenu.children.length > 0">
                            <a href="javascript:" class="gnb-link" :class="{'swcc-text-white': mode === 'dark'}" style="align-items: center">{{
                                parentsMenu.name
                            }}</a>
                            <div class="gnb-submenu" style="margin-top: 0" @click.stop="">
                                <ul class="submenu-list" style="width: 224px">
                                    <template v-for="(childMenu, idx) in parentsMenu.children" :key="`${index}-${idx}`">
                                        <li v-if="!childMenu.hide && (!childMenu.condition || childMenu.condition(session))" class="submenu-item">
                                            <router-link v-if="childMenu.uri" :to="childMenu.uri" class="submenu-link" @click="toggleActive(index)">{{
                                                childMenu.name
                                            }}</router-link>
                                            <a v-else href="javascript:" class="submenu-link">{{ childMenu.name }}</a>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </template>
                        <router-link v-else :to="parentsMenu.uri" class="gnb-link" :class="{'swcc-text-white': mode === 'dark'}" style="align-items: center">{{
                            parentsMenu.name
                        }}</router-link>
                    </li>
                </template>

                <li v-if="session.manager" class="gnb-item" style="height: 70px; display: flex; align-items: center">
                    <router-link :to="{name: 'Statistics'}" class="gnb-link" :class="{'swcc-text-white': mode === 'dark'}" style="align-items: center"
                        >통계</router-link
                    >
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import {computed, onMounted, onUnmounted} from 'vue';
import {useStore} from 'vuex';
import {getAuthSession} from '@/assets/js/modules/auth/module';

export default {
    name: 'CommonHeaderMenu',
    props: {
        mode: String,
    },
    setup() {
        const store = useStore();
        const session = computed(getAuthSession);
        const menus = computed(() => store.state.auth.menus);

        // const toggleActive = (index, toggle) => {
        //     menus.value[index].toggle = toggle;
        // };
        const toggleActive = index => {
            menus.value.forEach((_, idx) => {
                if (index === idx) {
                    menus.value[idx].toggle = !menus.value[idx].toggle;
                } else {
                    menus.value[idx].toggle = false;
                }
            });
        };

        const clickBody = () => {
            menus.value.forEach(m => {
                m.toggle = false;
            });
        };

        onMounted(() => {
            document.addEventListener('click', clickBody);
        });

        onUnmounted(() => {
            document.removeEventListener('click', clickBody);
        });

        return {
            session,
            menus,
            toggleActive,
        };
    },
};
</script>
